import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import MainButton from '@ludo.ninja/ui/build/system/Buttons/MainButton';
var LoginButton = function (_a) {
    var url = _a.url;
    return (_jsx(MainButton, { onClick: function () { return window.open(url, '_self'); }, variant: "primaryM", text: 'Join Ludo' }));
};
var AccessButton = function (_a) {
    var url = _a.url;
    return (_jsx(MainButton, { onClick: function () { return window.open(url, '_self'); }, variant: "primaryL", text: 'Get Early Access' }));
};
export var initializeMainButtonReact = function (_a) {
    var url = _a.url;
    var rootElement = document.getElementById('joinLudoBtn');
    var root = ReactDOM.createRoot(rootElement);
    root.render(_jsx(LoginButton, { url: url }));
};
export var initializeAccessButtonReact = function (_a) {
    var url = _a.url;
    var rootElement = document.getElementById('accessLudoBtn');
    var root = ReactDOM.createRoot(rootElement);
    root.render(_jsx(AccessButton, { url: url }));
};
