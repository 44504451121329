var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { doScroll } from "./doScroll";
export var scrollSnapInitHomePage = function () {
    var isFirefox = navigator.userAgent.includes("Firefox");
    var container = document.querySelector('.main_page');
    var sections = __spreadArray([], document.querySelectorAll('section'), true);
    if (!isFirefox) {
        container.style.scrollSnapType = 'y mandatory';
        sections.forEach(function (section) {
            section.style.scrollSnapAlign = 'start';
        });
    }
    else {
        var scrollTimeout_1 = null;
        var getSnapPosition_1 = function (scrollTop) {
            var position = scrollTop;
            sections.forEach(function (section, index) {
                if (scrollTop > section.offsetTop) {
                    var scrollInSection = scrollTop - section.offsetTop;
                    var sectionBottom = section.offsetTop + section.clientHeight;
                    if (index !== sections.length - 1) {
                        if (index === sections.length - 2 && sections[sections.length - 1].clientHeight < section.clientHeight) {
                            position = scrollTop;
                        }
                        else {
                            position = scrollInSection > section.clientHeight / 4 ? sectionBottom : section.offsetTop;
                        }
                    }
                    else {
                        position = scrollTop;
                    }
                }
            });
            return position;
        };
        container.addEventListener('scroll', function () {
            if (scrollTimeout_1) {
                clearTimeout(scrollTimeout_1);
            }
            scrollTimeout_1 = setTimeout(function () {
                var scrollTop = container.scrollTop;
                var position = getSnapPosition_1(scrollTop);
                if (position !== scrollTop) {
                    var sectionToScroll = sections.find(function (el) { return el.offsetTop === position; });
                    if (sectionToScroll) {
                        container.style.pointerEvents = 'none';
                        document.documentElement.style.pointerEvents = 'none';
                        document.documentElement.style.overflow = 'hidden';
                        doScroll(sectionToScroll, 200, container).then(function () {
                            container.style.pointerEvents = 'unset';
                            document.documentElement.style.pointerEvents = 'unset';
                            document.documentElement.style.overflow = 'unset';
                        });
                    }
                    // container.scrollTo({
                    //   top: position,
                    //   behavior: 'smooth'
                    // });
                }
            }, 100);
        });
    }
};
