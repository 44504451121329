var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Splide from "@splidejs/splide";
var sequentialImageLoader = function () {
    // create stack for sequential loading of images
    var stackLoaderSlides = [];
    // check when image from background is loaded
    var getImageLoaderFromPerformance = function () { return new Promise(function (resolve) {
        var timeStartLoadingImage = Date.now() - window.performance.timing.connectStart;
        var observer = new PerformanceObserver(function (list) {
            var entries = list.getEntries();
            entries.forEach(function (entry) {
                // Check is resource background image
                if (entry.entryType === 'resource' && entry.initiatorType === 'css' && entry.name.includes('.webp') && timeStartLoadingImage && entry.connectStart > timeStartLoadingImage) {
                    observer.disconnect();
                    resolve(entry);
                }
            });
        });
        observer.observe({ type: 'resource', buffered: true });
    }); };
    var startLoadingImage = function (images, slideCurrent) { return __awaiter(void 0, void 0, void 0, function () {
        var i;
        return __generator(this, function (_a) {
            //start loading
            for (i = 0; i < images.length; i++) {
                images[i].classList.add("bg-".concat(i + 1));
            }
            // check loading status
            getImageLoaderFromPerformance().then(function (entry) {
                //start animation when image slide is loaded and active
                if (slideCurrent.classList.contains('is-active')) {
                    for (var i = 0; i < images.length; i++) {
                        images[i].classList.add('animate');
                    }
                }
                // remove from stack after loading
                stackLoaderSlides = stackLoaderSlides.filter(function (el) { return el !== slideCurrent; });
                if (stackLoaderSlides.length) {
                    // start next loading for image from stack (recursive)
                    startLoadingImage(stackLoaderSlides[0].querySelectorAll('.bg'), stackLoaderSlides[0]);
                }
            });
            return [2 /*return*/];
        });
    }); };
    var getImageForSlide = function (slideCurrent) { return __awaiter(void 0, void 0, void 0, function () {
        var images, isNeedLoading, i;
        return __generator(this, function (_a) {
            // if image in loading skip add to loading
            if (stackLoaderSlides.find(function (el) { return el === slideCurrent; })) {
                return [2 /*return*/];
            }
            images = slideCurrent.querySelectorAll('.bg');
            isNeedLoading = !images[0].classList.contains('bg-1');
            // add animation if not loading image
            if (!isNeedLoading) {
                for (i = 0; i < images.length; i++) {
                    images[i].classList.add('animate');
                }
            }
            // add to loading
            if (isNeedLoading) {
                stackLoaderSlides.push(slideCurrent);
                // start loading if first element in stack, else loading image sequential recursive
                if (stackLoaderSlides.length !== 1)
                    return [2 /*return*/];
                startLoadingImage(images, slideCurrent);
            }
            return [2 /*return*/];
        });
    }); };
    return {
        getImageForSlide: getImageForSlide
    };
};
export var sliderInit = function () {
    var splide = new Splide('#splideMainSlider', {
        type: 'fade',
        arrows: false,
        autoplay: true,
        rewind: true,
        pauseOnHover: false,
        interval: 5000,
        // lazyLoad: 'nearby',
    });
    var getImageForSlide = sequentialImageLoader().getImageForSlide;
    splide.on('active', function (e) {
        var collectionSlide = document.getElementsByClassName('splide__slide');
        var currentSlide = collectionSlide[e.index].querySelector('.home_common');
        var collectionSlideCurrent = collectionSlide[e.index];
        if (collectionSlideCurrent) {
            getImageForSlide(collectionSlideCurrent);
        }
        currentSlide.classList.add('active');
    });
    splide.on('inactive', function (e) {
        var collectionSlide = document.getElementsByClassName('splide__slide');
        var currentSlide = collectionSlide[e.index].querySelector('.home_common');
        var collectionSlideCurrent = collectionSlide[e.index];
        if (collectionSlideCurrent) {
            var images = collectionSlideCurrent.querySelectorAll('.bg');
            for (var i = 0; i < images.length; i++) {
                images[i].classList.remove('animate');
            }
        }
        currentSlide.classList.remove('active');
    });
    splide.mount();
};
